import React from "react";

class MobileMenu extends React.Component {
    render() {
        return (

<nav role="navigation" className="mobile-only">
    <div id="menuToggle">
        <input type="checkbox" />

        <span></span>
        <span></span>
        <span></span>

        <ul id="menu">
            <a href="/"><li>Home</li></a>
            <a href="#"><li>Services</li></a>
            <a href="#"><li>About</li></a>
            <a href="#"><li>Contact</li></a>
        </ul>
    </div>
</nav>
);
}
}

export default MobileMenu;