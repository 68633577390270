import React from "react";
import axios from 'axios';

// const apiUrl = 'http://localhost:4444';

class Contact extends React.Component {

  state = {
        name: '',
        message: '',
        email: '',
        sent: false,
        buttonText: 'Send Message'
    }

formSubmit = (e) => {
  e.preventDefault()

  this.setState({
      buttonText: '...sending'
  })

  let data = {
      name: this.state.name,
      email: this.state.email,
      message: this.state.message
  }

  axios.post('/api/v1', data)
  .then( res => {
      this.setState({ sent: true }, this.resetForm())
  })
  .catch( () => {
    console.log('Message not sent')
  })
}

resetForm = () => {
    this.setState({
        name: '',
        message: '',
        email: '',
        buttonText: 'Message Sent'
    })
}

  render() {
    return (
      <div className="row justify-content-center">
      <div className="col-md-7">
        <div className="row mb-5 site-section">
          <div className="col-12 ">
            <h2 className="site-section-heading text-center">Contact Me</h2>
          </div>
        </div>

        <div className="row">
          <div className="col-lg-8 mb-5">
            <form onSubmit={ (e) => this.formSubmit(e)}>

              <div className="row form-group">
                <div className="col-md-12">
                  <label className="text-black" for="fname">Your Name</label>
                  <input type="text" name="name" id="name" className="form-control" onChange={e => this.setState({ name: e.target.value})}/>
                </div>
              </div>

              <div className="row form-group">

                <div className="col-md-12">
                  <label className="text-black" for="email">Email</label>
                  <input type="email" name="email" id="email" className="form-control"
                  onChange={(e) => this.setState({ email: e.target.value})}
                  />
                </div>
              </div>

              <div className="row form-group">
                <div className="col-md-12">
                  <label className="text-black" for="message">Message</label>
                  <textarea name="message" name="message" id="message" cols="30" rows="7" className="form-control" placeholder="Write your message here..."
                  onChange={e => this.setState({ message: e.target.value})}
                  ></textarea>
                </div>
              </div>

              <div className="row form-group">
                <div className="col-md-12">
                  <input type="submit" value={ this.state.buttonText } className="btn btn-primary py-2 px-4 text-white"/>
                </div>
              </div>

            </form>
          </div>
          <div className="col-lg-3 ml-auto">
            <div className="mb-3 bg-white">
              <p className="mb-0 font-weight-bold">Email Address</p>
              <p className="mb-0"><a href="mailto:samis3000@gmail.com">samis3000[at]gmail.com</a></p>
            </div>
          </div>
        </div>
      </div>
      </div>
    );
  }
}

export default Contact;
