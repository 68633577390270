import React, {useState, useCallback, useEffect} from "react";
import Gallery from "react-photo-gallery";
import Carousel, {Modal, ModalGateway} from "react-images";
import About from "./components/About";
import Contact from "./components/Contact";
import Services from "./components/Services";
import Icon from "./components/Icon";
import MobileMenu from "./components/MobileMenu";

import {photos} from "./photos";

import "./styles/bootstrap.min.css";
import "./styles/App.scss";

function App() {
  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);

  const [sectionVisible, setSectionVisible] = useState("gallery");

  const openLightbox = useCallback((event, {photo, index}) => {
    setCurrentImage(index);
    setViewerIsOpen(true);
  }, []);

  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };

  const ActiveComponent = ({sectionVisible}) => {
    if (sectionVisible === "gallery") {
      return (
        <React.Fragment>
          <Gallery photos={photos} onClick={openLightbox} direction="row" />
          <ModalGateway>
            {viewerIsOpen ? (
              <Modal onClose={closeLightbox}>
                <Carousel
                  currentIndex={currentImage}
                  views={photos.map(x => ({
                    ...x,
                    srcset: x.srcSet,
                    caption: x.title
                  }))}
                />
              </Modal>
            ) : null}
          </ModalGateway>
        </React.Fragment>
      );
    }
    if (sectionVisible === "services") {
      return <Services></Services>;
    }
    if (sectionVisible === "about") {
      return <About></About>;
    }
    if (sectionVisible === "contact") {
      return <Contact></Contact>;
    }
    return null;
  };

  return (
    <div className="site-wrap">
      <div className="site-mobile-menu">
        <div className="site-mobile-menu-header">
          <div className="site-mobile-menu-close mt-3">
            <span className="icon-close2 js-menu-toggle"></span>
          </div>
        </div>
        <div className="site-mobile-menu-body"></div>
      </div>

      <header className="site-navbar py-3" role="banner">
        <div className="container-fluid">
          <div className="row align-items-center">
            <div className="col-6 col-xl-2" data-aos="fade-down">
              <h1 className="mb-0">
                <a href="/" className="text-black h2 mb-0">
                  samis shaudo
                </a>
              </h1>
            </div>

            <div className="col-10 col-md-8 d-none d-xl-block" data-aos="fade-down">
              <nav className="site-navigation position-relative text-right text-lg-center" role="navigation">
                <ul className="site-menu js-clone-nav mx-auto d-none d-lg-block">
                  <li>
                    <a href="#" onClick={() => setSectionVisible("gallery")}>Home</a>
                  </li>
                  <li>
                    <a href="#" onClick={() => setSectionVisible("services")}>Services</a>
                  </li>
                  <li>
                    <a href="#" onClick={() => setSectionVisible("about")}>About</a>
                  </li>
                  <li>
                    <a href="#" onClick={() => setSectionVisible("contact")}>Contact</a>
                  </li>
                </ul>
              </nav>
            </div>

            <div className="col-6 col-xl-2 text-right" data-aos="fade-down">
              <div className="d-none d-xl-inline-block">
                <ul className="site-menu js-clone-nav ml-auto list-unstyled d-flex text-right mb-0" data-classname="social">
                  <li>
                    <a href="#" className="pl-0 pr-3">
                      <span className="icon-facebook"></span>
                    </a>
                  </li>
                  <li>
                    <a href="#" className="pl-3 pr-3">
                      <span className="icon-twitter"></span>
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.instagram.com/samis.shaudo"
                      className="pl-3 pr-3"
                    >
                      <span className="icon-instagram"></span>
                    </a>
                  </li>
                  <li>
                    <a href="#" className="pl-3 pr-3">
                      <span className="icon-youtube-play"></span>
                    </a>
                  </li>
                </ul>
              </div>

              <div className="d-inline-block d-xl-none ml-md-0 mr-auto py-3">
                <a
                  href="#"
                  className="site-menu-toggle js-menu-toggle text-black"
                >
                  <span className="icon-menu h3"></span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </header>
      <div
        className="container-fluid full-height"
        data-aos="fade"
        data-aos-delay="500"
      >
        <ActiveComponent sectionVisible={sectionVisible}></ActiveComponent>
      </div>
      <div className="footer py-4">
        <div className="container-fluid">
          <p>
            Copyright &copy; samis shaudo | All rights reserved |{" "}
            {new Date().getFullYear()}
          </p>
        </div>
      </div>
    </div>
  );
}

export default App;
