import React from "react";

class About extends React.Component {
  render() {
    return (
      <div className="row justify-content-center">
        <div className="col-md-7">
          <div className="row mb-5 site-section">
            <div className="col-12 ">
              <h2 className="site-section-heading text-center">About Me</h2>
            </div>
          </div>
          <div className="row mb-5">
            <div className="col-md-7">
              <img
                src="img/profile_1.jpg"
                alt="Samis G"
                className="img-fluid"
              />
            </div>
            <div className="col-md-4 ml-auto">
              <h3>My Hobby</h3>
              <p>
                Photography is my constantly growing hobby. I like capturing
                images of things around me. I fell in love with manual focus
                lenses. I'm mainly using them to get a unique look. The feeling
                of working with old manual lenses is different too. It's much
                more involved.
              </p>
              <p>Let's create something beautiful together.
                I think it's fun for both the photographer and the model.
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default About;
