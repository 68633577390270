export const photos = [
  // 800x600
  // arba 600x800

  {
    src: "photos/001.jpg",
    width: 4,
    height: 3
  },
  {
    src: "photos/002.jpg",
    width: 3,
    height: 4
  },
  {
    src: "photos/003.jpg",
    width: 3,
    height: 4
  },
  {
    src: "photos/004.jpg",
    width: 3,
    height: 4
  },
  {
    src: "photos/005.jpg",
    width: 3,
    height: 4
  },
  {
    src: "photos/006.jpg",
    width: 3,
    height: 4,
    alt: "Bridge"
  },
  {
    src: "photos/007.jpg",
    width: 3,
    height: 4
  },
  {
    src: "photos/008.jpg",
    width: 3,
    height: 4
  },
  {
    src: "photos/009.jpg",
    width: 3,
    height: 4
  },
  {
    src: "photos/010.jpg",
    width: 4,
    height: 3
  },
  {
    src: "photos/011.jpg",
    width: 3,
    height: 4
  },
  {
    src: "photos/012.jpg",
    width: 3,
    height: 4
  },
  {
    src: "photos/013.jpg",
    width: 3,
    height: 4
  },
  {
    src: "photos/028.jpg",
    width: 3,
    height: 4
  },
  {
    src: "photos/30.jpg",
    width: 3,
    height: 4
  },
  {
    src: "photos/020.jpg",
    width: 3,
    height: 4
  },
  {
    src: "photos/021.jpg",
    width: 3,
    height: 4,
    alt: "Moss"
  },
  {
    src: "photos/022.jpg",
    width: 3,
    height: 4
  },
  {
    src: "photos/023.jpg",
    width: 3,
    height: 4
  },
  {
    src: "photos/024.jpg",
    width: 4,
    height: 3
  },
  {
    src: "photos/025.jpg",
    width: 4,
    height: 3
  },
  {
    src: "photos/31.jpg",
    width: 3,
    height: 4
  },
  {
    src: "photos/32.jpg",
    width: 3,
    height: 4
  },
  {
    src: "photos/033.jpg",
    width: 3,
    height: 4
  },
  {
    src: "photos/35.jpg",
    width: 3,
    height: 4
  },
    {
    src: "photos/038.jpg",
    width: 3,
    height: 4
  },
  {
    src: "photos/36.jpg",
    width: 3,
    height: 4
  },
  {
    src: "photos/037.jpg",
    width: 3,
    height: 4
  },
      {
    src: "photos/039.jpg",
    width: 3,
    height: 4
  }
];
