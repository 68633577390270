import React from "react";

class Services extends React.Component {
  render() {
    return (
      <div className="row justify-content-center">
      <div className="col-md-7">
        <div className="row mb-5 site-section">
          <div className="col-12 ">
            <h2 className="site-section-heading text-center">My Services</h2>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6 col-lg-6 col-xl-4 text-center mb-5 mb-lg-5">
            <div className="h-100 p-4 p-lg-5 bg-light site-block-feature-7">
              <span className="icon flaticon-frame display-3 text-primary mb-4 d-block"></span>
              <h3 className="text-black h4">Portrait</h3>
              <p>Need a good looking picture of yourself? You're on the right place.</p>
              <p><strong className="font-weight-bold text-primary"> €50/h</strong></p>
            </div>
          </div>
          <div className="col-md-6 col-lg-6 col-xl-4 text-center mb-5 mb-lg-5">
            <div className="h-100 p-4 p-lg-5 bg-light site-block-feature-7">
              <span className="icon flaticon-eiffel-tower display-3 text-primary mb-4 d-block"></span>
              <h3 className="text-black h4">Automotive</h3>
              <p>I really like taking pictures of cars. Let me help you by photographing yours.</p>
              <p><strong className="font-weight-bold text-primary"> €55/h</strong></p>
            </div>
          </div>
          <div className="col-md-6 col-lg-6 col-xl-4 text-center mb-5 mb-lg-5">
            <div className="h-100 p-4 p-lg-5 bg-light site-block-feature-7">
              <span className="icon flaticon-video-play display-3 text-primary mb-4 d-block"></span>
              <h3 className="text-black h4">Video Editing</h3>
              <p>Sometimes it's fun to watch moving pictures too. I also offer video editing.</p>
              <p><strong className="font-weight-bold text-primary"> €60/h</strong></p>
            </div>
          </div>

        </div>
      </div>
      </div>
    );
  }
}

export default Services;
